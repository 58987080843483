import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { ASK_ASATO_DEMO_STUB } from 'constants/AskAsatoStubData';
import { configuration } from 'services/baseApiService';
import { toUrlParams, transformAdminOnboardingData } from 'utils';

import axios from './interceptors';

const loginDetails = JSON.parse(localStorage.getItem('userDetails'));
const persona = localStorage.getItem('persona');
const InitialPersona = loginDetails?.user?.personas[0]?.name;
const userCustomerId = loginDetails?.user?.customer?.id;
const userId = loginDetails?.user?.id;

export const getTokenFromSessionStorage = () => {
  //keycloak
  // if (localStorage.getItem('accessToken')) {
  //   return localStorage.getItem('accessToken');
  // }

  return localStorage.getItem('jwtToken');
};

//token for change password-
export const sendToken = () => {
  const token = getTokenFromSessionStorage();
  let authToken = null;
  let headers = {};

  if (token) {
    try {
      const tokenObj = JSON.parse(token);
      authToken = tokenObj.secret;
    } catch (error) {
      authToken = token;
    }
  }

  if (!authToken) {
    console.error('No token found in session storage.');
  } else {
    headers['Content-Type'] = `application/json`;
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  return { headers };
};

//token for all api calls
export const createHeadersWithToken = () => {
  // const persona = localStorage.getItem('persona');
  const token = getTokenFromSessionStorage();

  let headers = {};

  if (!token) {
    console.error('No token found in storage.');
  } else {
    headers['Content-Type'] = `application/json`;
    headers['Authorization'] = `Bearer ${token}`;
  }

  headers['Cache-Control'] = 'no-cache';

  return { headers };
};

// askAsato
export const askAsatoToken = () => {
  const token = getTokenFromSessionStorage();
  let authToken = null;
  let headers = {};

  if (token) {
    try {
      const tokenObj = JSON.parse(token);
      authToken = tokenObj.secret;
    } catch (error) {
      authToken = token;
    }
  }

  if (!authToken) {
    console.error('No token found in session storage.');
  } else {
    headers['Content-Type'] = `application/json`;
    headers['Authorization'] = `Bearer ${authToken}`;
    headers['Cache-Control'] = 'no-cache';
  }

  return { headers };
};

//file upload
const createHeadersWithTokenFile = () => {
  const token = getTokenFromSessionStorage();
  let authToken = null;
  let headers = {};

  if (token) {
    try {
      const tokenObj = JSON.parse(token);
      authToken = tokenObj.secret;
    } catch (error) {
      authToken = token;
    }
  }

  if (!authToken) {
    console.error('No token found in session storage.');
  } else {
    headers['Content-Type'] = `multipart/form-data`;
    headers['Authorization'] = `Bearer ${authToken}`;
    headers['Cache-Control'] = 'no-cache';
  }

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  if (userDetails && userDetails.user) {
    // console.log('headerone', userDetails.user.customer.id);
    headers['customerId'] = userCustomerId;

    if (userDetails.user.personas && userDetails.user.personas.length > 0) {
      // console.log('headertwo', userDetails.user.personas[0].id);
      headers['currentPersonaId'] = persona ? persona : InitialPersona;
    }
  }

  return { headers };
};
// console.log('createHeadersWithToken', createHeadersWithTokenFile());

//...............................................................................................................

// Manual login
export const login = async (email, password) => {
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Cache-Control': 'no-cache',
      'Tenant-Name': null
    };

    const requestBody = {
      user_name: email,
      password: password
    };

    const response = await axios.post(`${configuration.newApiBaseUrl}auth/manual-login`, requestBody, { headers });

    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error('An unexpected error occurred during login');
  }
};

// Manual login
// export const login = async (email, password) => {
//   try {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Cache-Control': 'no-cache'
//     };

//     const requestBody = {
//       userLoginEmail: email,
//       userLoginPassword: password
//     };

//     const response = await axios.post(`${configuration.apiBaseUrl}login`, requestBody, { headers });

//     localStorage.setItem('jwtToken', response.data.jwtToken);
//     localStorage.setItem('refreshToken', response.data.refreshToken);
//     return response.data;
//   } catch (error) {
//     throw error.response ? error.response.data : new Error('An unexpected error occurred during login');
//   }
// };

// Fetch user details function
export const fetchUserDetails = async () => {
  try {
    const response = await axios.get(`${configuration.newApiBaseUrl}token/user-details`, createHeadersWithToken());
    localStorage.setItem('userDetails', JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch user details');
  }
};

export const fetchConfigRoutes = async ({ tenantId }) => {
  try {
    const response = await axios.get(
      `${configuration.insightsDevBaseUrl}screens/ui/routes?tenant_id=${tenantId}`,
      createHeadersWithToken()
    );
    return response?.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch routes');
  }
};

//card details - NO, IT IS NOT STUBBED DATA
export const getStubData = createAsyncThunk('stubData', async (received, thunkAPI) => {
  const from = received?.startDate ? received?.startDate : moment().subtract(3, 'month').format('YYYY-MM-DD');
  const to = received?.endDate ? received?.endDate : moment().format('YYYY-MM-DD');

  let Url = `${configuration.apiBaseUrl}card?fromDate=${from}&toDate=${to}&screen=${received?.screen}&section=${received?.section}`;

  // https://application-service.azurewebsites.net/api/v1/card?fromDate=2023-01-01&toDate=2025-01-01&screen=home&section=overview

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'Stub Data' });
  }
});

//card table
export const getTableData = createAsyncThunk('tableData', async (useDetail, thunkAPI) => {
  let Url;
  if (useDetail.title === 'Total Users') {
    Url = `${configuration.apiBaseUrl}users?customerId=${useDetail.id}&fromDate=${useDetail.startDate}&toDate=${useDetail.endDate}&screen=${useDetail.screen}&section=${useDetail.section}&currentPersona=${useDetail.persona}`;
  }
  if (useDetail.title === 'Total Expenses') {
    Url = `${configuration.apiBaseUrl}cost?customerId=${useDetail.id}&fromDate=${useDetail.startDate}&toDate=${useDetail.endDate}&screen=${useDetail.screen}&section=${useDetail.section}`;
  }
  if (
    useDetail.title === 'Total Applications' ||
    useDetail.title === 'Total Assets' ||
    useDetail.title === 'Total Apps Data' ||
    useDetail.title === 'IT Verified Apps'
  ) {
    Url = `${configuration.apiBaseUrl}apps?customerId=${useDetail.id}&fromDate=${useDetail.startDate}&toDate=${useDetail.endDate}&screen=${useDetail.screen}&section=${useDetail.section}`;
  }

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) {
      if (useDetail.type === 'adminCost') {
        return { data: res.data, type: 'adminCost' };
      }
      return res.data;
    }
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Table Data' });
  }
});

//Insights details
export const getInsightsData = createAsyncThunk('Insights', async (received, thunkAPI) => {
  const from = received?.startDate ? received?.startDate : moment().subtract(3, 'month').format('YYYY-MM-DD');
  const to = received?.endDate ? received?.endDate : moment().format('YYYY-MM-DD');
  let Url = `${configuration.apiBaseUrl}insights/data?customerId=${userCustomerId}&userId=${userId}&fromDate=${from}&toDate=${to}&screen=${received?.screen}&userPersona=${received?.persona}`;
  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) return res?.data?.data?.insights;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'Insights' });
  }
});

export const getForceGraphData = async ({ tenantId, assetId, assetType, fieldName }) => {
  try {
    const response = await axios.get(
      `${configuration.adminURL}asset-view/node?tenant_id=${tenantId}&asset_id=${assetId}&asset_type=${assetType}&field_name=${fieldName}`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch force graph data');
  }
};

export const getForceGraphEdgesData = async ({ tenantId, edgeId }) => {
  try {
    const response = await axios.get(`${configuration.adminURL}asset-view/edges?tenant_id=${tenantId}&asset_id=${edgeId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch force graph node data');
  }
};

//get user table values - Access Mgmt
export const getAccessData = createAsyncThunk('Access', async (options, thunkAPI) => {
  const { page_number = 0, page_size = 10, has_page_count = true, sort_by, sort_order, download, search_query, search_column } = options;

  const params = new URLSearchParams({
    page_number,
    ...(page_size && { page_size }),
    ...(has_page_count && { has_page_count }),
    ...(sort_by && { sort_by }),
    ...(sort_order && { sort_order }),
    ...(download && { download }),
    ...(search_query && { search_query }),
    ...(search_column && { search_column })
  });
  try {
    let res = await axios.get(`${configuration.newApiBaseUrl}user/?${params.toString()}`, createHeadersWithToken());
    // const res = await axios.get(`http://localhost:5001/data?${params.toString()}`, createHeadersWithToken());
    if (res) return res.data;
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Access' });
  }
});

export const getTaskData = createAsyncThunk('task_data', async (thunkAPI) => {
  try {
    // let res = await axios.get(`${configuration.newApiBaseUrl}user/?${params.toString()}`, createHeadersWithToken());
    let res = await axios.get('http://localhost:4102/task');

    if (res) return res.data;
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Access' });
  }
});

//add user - Access Mgmt
export const AddAccessData = createAsyncThunk('Add Access', async (newUser, thunkAPI) => {
  // const customerId = userCustomerId;
  // const currentPersona = 'executive';
  const options = {
    method: 'POST',
    ...createHeadersWithToken(),
    body: JSON.stringify(newUser)
  };
  try {
    // let res = await axios.post(`${configuration.apiBaseUrl}users&customerId=${customerId}&currentPersona=${currentPersona}`, newUser,createHeadersWithToken());
    let res = await fetch(`${configuration.newApiBaseUrl}user/`, options);
    if (res) {
      return res;
      // alert('New User Added');
      // window.location.reload();
    }
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Add Access' });
  }
});

//update - Access Mgmt
export const UpdateAccessData = createAsyncThunk('UpdateAccess', async (Updated, thunkAPI) => {
  // const customerId = userCustomerId;
  // const currentPersona = 'executive';
  const options = {
    method: 'PUT',
    ...createHeadersWithToken(),
    body: JSON.stringify(Updated)
  };
  try {
    // let res = await axios.post(`${configuration.apiBaseUrl}users&customerId=${customerId}&currentPersona=${currentPersona}`, newUser,createHeadersWithToken());
    let res = await fetch(`${configuration.newApiBaseUrl}user/`, options);
    // let res = await fetch('https://application-service.azurewebsites.net/api/v1/users?customerId=drivewyze&currentPersonaId=admin',options);

    if (res) {
      return res;
      // alert('Updated Successfully');
      // window.location.reload();
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'Update Access' });
  }
});

//report builder card list
//Depricated
export const getReports = createAsyncThunk('Reports', async (Details, thunkAPI) => {
  const userId = loginDetails?.user?.displayName;
  try {
    let res = await axios.get(
      `${configuration.apiBaseUrl}insights?customerId=${Details.id}&status=${Details?.status}&userId=${userId}`,
      createHeadersWithToken()
    );

    if (res) {
      return { data: res?.data?.data?.insights, status: Details?.status };
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'Reports' });
  }
});

export const getInsightsList = async ({ tenantId, role, status, fromDate, toDate }) => {
  try {
    const response = await axios.get(
      `${configuration.insightsDevBaseUrl}lifecycle/insights/status?tenant_id=${tenantId}&role=${role}&status=${status}&from_date=${fromDate}&to_date=${toDate}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromSessionStorage()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

// Add draft for report Builder
export const AddReportDraft = createAsyncThunk('Add Report', async (Details, thunkAPI) => {
  let Url = `${configuration.apiBaseUrl}insights/draft`;
  //   const options = {
  //     method: "POST",
  //     ...createHeadersWithToken(),
  //     body:JSON.stringify(Details),
  //  };

  try {
    // let res = fetch(Url,options)
    let res = await axios.post(Url, Details, createHeadersWithToken());

    if (res) return res.data;
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Add Report' });
  }
});

export const createInsights = async ({ tenantId, payload, draft, role }) => {
  try {
    const url = `${configuration.insightsDevBaseUrl}lifecycle/insights?tenant_id=${tenantId}&role=${role}&is_draft=${draft}`;
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
    throw error.response ? error.response.data : console.error('An unexpected error occurred during insights creation');
  }
};

export const updateInsightsApi = async ({ tenantId, payload, role, change_status, insightId }) => {
  try {
    const url = `${configuration.insightsDevBaseUrl}lifecycle/insights/update?insight_id=${insightId}&tenant_id=${tenantId}&role=${role}&change_status=${change_status}`;
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

// Submit for report Builder
export const CreateReport = createAsyncThunk('Submit Report', async (Details, thunkAPI) => {
  try {
    let res = await axios.post(
      `${configuration.apiBaseUrl}insights/create?customerId=${userCustomerId}`,
      Details,
      createHeadersWithToken()
    );
    if (res) {
      // alert('Submitted Successfully')
      return res;
    }
  } catch (error) {
    if (error) {
      // console.log('error12', error)
      // console.log('error', thunkAPI.rejectWithValue(error.message))
      return thunkAPI.rejectWithValue({ ...error, name: 'Submit Report' });
    }
  }
});
//File Upload

export const getFileDetails = createAsyncThunk('File Upload', async (details, thunkAPI) => {
  const { customerId, files, role } = details;

  const url = `${configuration.insightsDevBaseUrl}lifecycle/insights/attachments?tenant_id=${customerId}&role=${role}`;
  // console.log('Url', url);

  const formData = new FormData();

  files.forEach((file) => {
    formData.append('file', file, file.name);
  });
  // formData.append('attachment', files[0], files[0].name);

  const options = {
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`,
      'Content-Type': 'multipart/form-data' // Set the content type to 'multipart/form-data'
    }
  };

  const axiosConfig = {
    method: 'post',
    url: url,
    data: formData,
    ...options
  };

  try {
    let res = await axios(axiosConfig);

    return res.data;
  } catch (error) {
    console.error('Error during file upload:', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'File Upload' });
  }
});

//  Generic file upload

export const genericFileUpload = async (options, api_url) => {
  const { tenant_id, upload_type, files } = options;
  const params = new URLSearchParams({
    tenant_id,
    ...(upload_type && { upload_type })
  });

  const url = `${configuration.adminURL}${api_url}?${params.toString()}`;

  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file, file.name);
  });

  const Details = {
    headers: {
      ...createHeadersWithTokenFile().headers,
      'Content-Type': 'multipart/form-data'
    }
  };

  const axiosConfig = {
    method: 'post',
    url: url,
    data: formData,
    ...Details
  };
  try {
    let res = await axios(axiosConfig);
    return res;
  } catch (error) {
    console.error('Error during file upload:', error);
    return error;
  }
};

//Report User List
export const getReportUsers = createAsyncThunk('Report Users', async (useDetail, thunkAPI) => {
  const startDate = moment('2025-01-01').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  try {
    let res = await axios.get(
      `${configuration.apiBaseUrl}asato-users?customerId=${useDetail.customerId}&fromDate=${startDate}&toDate=${endDate}&screen=${useDetail.screen}&section=${useDetail.section}&currentPersona=${useDetail?.persona}`,
      createHeadersWithToken()
    );

    if (res) return res.data;
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Report Users' });
  }
});

//unique draft id
//:Depricated
export const getUniqueDraft = createAsyncThunk('Unique draft', async (useId, thunkAPI) => {
  try {
    let res = await axios.get(`${configuration.apiBaseUrl}insights/${useId}`, createHeadersWithToken());

    if (res) return res.data;
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Unique Draft' });
  }
});

export const getInsightDetail = async ({ insightId, tenantId, role }) => {
  try {
    const response = await axios.get(
      `${configuration.insightsDevBaseUrl}lifecycle/insights/${insightId}?tenant_id=${tenantId}&role=${role}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromSessionStorage()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

//update draft
export const UpdateUniqueDraft = createAsyncThunk('Update draft', async (Details, thunkAPI) => {
  let Url = `${configuration.apiBaseUrl}insights/update`;
  try {
    let res = await axios.put(Url, Details, createHeadersWithToken());

    if (res) {
      return res;
      // alert('Submitted Successfully')
    }
    // window.location.href = '/report';
  } catch (error) {
    // console.log('err in api', thunkAPI.rejectWithValue(error))
    if (error) return thunkAPI.rejectWithValue(error?.response?.data?.error);
  }
});

//Delete Draft

// export const DeleteDraftCard = createAsyncThunk('Delete draft', async (id, thunkAPI) => {
//   // const user = loginDetails?.user?.displayName;
//   // console.log('user', user);
//   let Url = `${configuration.apiBaseUrl}insights`;
//   const bodyw = {
//     insightId: id,
//     comments: ''
//   };
//   const options = {
//     method: 'DELETE',
//     ...createHeadersWithToken(),
//     body: JSON.stringify(bodyw)
//   };

//   try {
//     let res = await fetch(Url, options);
//     // let res = await axios.delete(Url, options);

//     if (res) {
//       return res;
//       // alert('Deleted Successfully');
//       // window.location.href = '/report';
//     }
//   } catch (error) {
//     if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Delete Draft' });
//   }
// });

export const deleteInsight = async ({ insightId, tenantId, role }) => {
  try {
    const url = `${configuration.insightsDevBaseUrl}lifecycle/insights/delete?insight_id=${insightId}&tenant_id=${tenantId}&role=${role}&comments`;
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

// Enable/Disable a user
export const ToggleUserEnable = createAsyncThunk('User Availability', async (details, thunkAPI) => {
  const { customerId, isEnabled, index } = details;
  let Url = `${configuration.newApiBaseUrl}user/${customerId}?is_enabled=${isEnabled}`;
  try {
    let res = await axios.patch(Url, details, createHeadersWithToken());
    res.index = index;
    if (res) {
      console.log('Updated Successfully');
      return res;
    }
  } catch (error) {
    if (error) {
      console.error('error');
      return thunkAPI.rejectWithValue({ ...error, name: 'User Availability' });
    }
  }
});

// For Apps

export const getAppUsage = createAsyncThunk('App Usage', async (details, thunkAPI) => {
  const { customerId, fromDate, toDate, screen, section } = details;
  let Url = `${configuration.apiBaseUrl}apps?customerId=${customerId}&fromDate=${fromDate}&toDate=${toDate}&screen=${screen}&section=${section}`;
  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    if (error) {
      console.error('error');
      return thunkAPI.rejectWithValue({ ...error, name: 'App Usage' });
    }
    // decision-tracker/list
  }
});

export const updateAppUsage = createAsyncThunk('App Update', async (details, thunkAPI) => {
  const { customerId, payload, fromDate, toDate, screen, section } = details;
  let Url = `${configuration.apiBaseUrl}apps?customerId=${customerId}`;
  try {
    const res = await axios.put(Url, payload, createHeadersWithToken());
    if (res.data && screen === 'app') {
      const mainUrl = `${configuration.apiBaseUrl}apps?customerId=${customerId}&fromDate=${fromDate}&toDate=${toDate}&screen=${screen}&section=${section}`;

      const res = await axios.get(mainUrl, createHeadersWithToken());
      if (res.data) {
        return res.data;
      }
    } else if (res.data) {
      return res.data;
    }
  } catch (error) {
    if (error) {
      console.error('error');
      return thunkAPI.rejectWithValue({ ...error, name: 'App Update' });
    }
    // decision-tracker/list
  }
});

export const bulkUpload = async (details, customerId, type = '') => {
  let Url = `${configuration.apiBaseUrl}${type}/bulk-upload?customerId=${customerId}`;
  const options = {
    headers: {
      ...createHeadersWithTokenFile().headers,
      'Content-Type': 'multipart/form-data' // Set the content type to 'multipart/form-data'
    }
  };
  const axiosConfig = {
    method: 'post',
    url: Url,
    data: details,
    ...options
  };
  try {
    const res = await axios(axiosConfig);
    return res.data;
  } catch (error) {
    if (error) console.error('error', error.message);
  }
};

export const decisionTrackerList = async ({ tenantId, fromDate, toDate, role }) => {
  try {
    const response = await axios.get(
      `${configuration.insightsDevBaseUrl}decision/list?tenant_id=${tenantId}&from_date=${fromDate}&to_date=${toDate}&role=${role}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromSessionStorage()}`
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error('Error during file upload:', err);
  }
};

export const getDecisionTrackerList = async ({ tenantId, fromDate, toDate, role }) => {
  try {
    const response = await axios.get(
      `${configuration.insightsDevBaseUrl}decision/list?tenant_id=${tenantId}&from_date=${fromDate}&to_date=${toDate}&role=${role}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromSessionStorage()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

export const userListForAdmin = createAsyncThunk('Admin User List', async (details, thunkAPI) => {
  const { fromDate = '', toDate = '', customerId = '', screen = '', section = '', currentPersona = '' } = details;
  let Url = `${configuration.apiBaseUrl}users?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}&screen=${screen}&section=${section}&currentPersona=${currentPersona}`;
  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res.data) {
      console.log('Fetched Successfully!');
      return res.data || [];
    }
  } catch (error) {
    if (error) {
      console.error('error');
      return thunkAPI.rejectWithValue({ ...error, name: 'Admin User List' });
    }
  }
});

export const fetchDetailedTable = createAsyncThunk('Card Table Data', async (details, thunkAPI) => {
  const { fromDate = '', toDate = '', screen = '', section = '', type = '', tableTitle = '', id = '' } = details;
  let Url = `${configuration.apiBaseUrl}details?fromDate=${fromDate}&toDate=${toDate}&screen=${screen}&section=${section}&type=${type}&id=${id}`;
  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) {
      return { data: res, tableTitle } || [];
    }
  } catch (error) {
    if (error) {
      console.error('error');
      return thunkAPI.rejectWithValue({ ...error, name: 'Card Table Data' });
    }
  }
});

//charts
export const analysisCharts = createAsyncThunk('analysisCharts', async (received, thunkAPI) => {
  const from = received?.startDate ? received?.startDate : moment().subtract(3, 'month').format('YYYY-MM-DD');
  const to = received?.endDate ? received?.endDate : moment().format('YYYY-MM-DD');

  let Url = `${configuration.apiBaseUrl}chart?fromDate=${from}&toDate=${to}&screen=${received?.screen}&section=${received?.section}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) return res.data;
    // console.log('respgetcharts', res);
  } catch (error) {
    console.error('chart err : ', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Analysis Charts' });
  }
});

export const updateUserDetails = createAsyncThunk('userListAdmin', async (payload, thunkAPI) => {
  let Url = `${configuration.apiBaseUrl}asato-users?customerId=${userCustomerId}&currentPersonaId=${persona}`;

  try {
    const res = await axios.put(Url, payload, createHeadersWithToken());
    return res.data;
  } catch (error) {
    console.error('error', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'User Details' });
  }
});

//forget password
export const forgetPassword = async (email) => {
  try {
    const response = await axios.post(`${configuration.newApiBaseUrl}auth/forgot-password?email=${email}`);

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('error in change pass');
  }
};

//change password from email
export const resetPassword = async (token, id, password) => {
  try {
    const response = await axios.post(
      `${configuration.newApiBaseUrl}auth/reset-password`,
      { new_password: password, user_id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error('An unexpected error occurred during password change');
  }
};

export const adminOnboarding = createAsyncThunk('adminOnboarding', async (payload, thunkAPI) => {
  const { customerId, fromDate, toDate } = payload;
  const Url = `${configuration.apiBaseUrl}onboarding/status?customerId=${customerId}&fromDate=${fromDate}&toDate=${toDate}`;
  try {
    const response = await axios.get(Url, createHeadersWithToken());
    return transformAdminOnboardingData(response.data);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Admin Onboarding' });
  }
});

//change password via settings
// export const ChangePassword = async (oldLoginPassword, newLoginPassword) => {
//   const url = `${configuration.apiBaseUrl}reset-password`;

//   // console.log('oldLoginPassword', oldLoginPassword);
//   // console.log('newLoginPassword', newLoginPassword);
//   const bodyw = {
//     oldLoginPassword,
//     newLoginPassword
//   };
//   const options = {
//     method: 'POST',
//     ...sendToken(),
//     body: JSON.stringify(bodyw)
//   };

//   try {
//     let res = await fetch(url, options);

//     if (res) {
//       return res;
//     }
//   } catch (error) {
//     if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Change Password' });
//   }
// };

export const ChangePassword = async (oldLoginPassword, newLoginPassword) => {
  const url = `${configuration.newApiBaseUrl}auth/change-password`;

  const options = {
    headers: {
      ...sendToken().headers
    }
  };

  const body = {
    oldLoginPassword,
    newLoginPassword
  };
  const option = {
    method: 'POST',
    body: JSON.stringify(body),
    ...options
  };

  try {
    let res = await fetch(url, option);

    if (res) {
      return res;
    }
  } catch (error) {
    // TODO: Add thunkAPI or handle error
    // eslint-disable-next-line no-undef
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Change Password' });
  }
};

//Depricated
export const setDecisionDetail = createAsyncThunk('decision/detail', async (id, thunkAPI) => {
  const Url = `${configuration.apiBaseUrl}decision-tracker/${id}`;
  try {
    const response = await axios.get(Url, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Decision Detail' });
  }
});

export const getRecommendationDetails = async ({ recommendationId, tenantId, role }) => {
  const url = `${configuration.insightsDevBaseUrl}decision/${recommendationId}?tenant_id=${tenantId}&role=${role}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

//Depricated
export const updateStatus = createAsyncThunk('Update Status', async (payload, thunkAPI) => {
  try {
    const response = await axios.put(
      `${configuration.apiBaseUrl}decision-tracker/decision/update-status`,
      payload,
      createHeadersWithToken()
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'Update Status' });
  }
});

export const updateTrackerStatus = async ({ tenantId, payload, role }) => {
  try {
    const url = `${configuration.insightsDevBaseUrl}decision/update-status?tenant_id=${tenantId}&role=${role}`;
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

//Depricated
export const updateAssignee = createAsyncThunk('Update Assignee', async (payload, thunkAPI) => {
  try {
    const response = await axios.put(`${configuration.apiBaseUrl}insights/recommendation/update`, payload, createHeadersWithToken());

    return response.data;
  } catch (error) {
    console.error('error : ', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Update Assignee' });
  }
});

export const updateRecommendationAssignee = async ({ tenantId, payload, role }) => {
  try {
    const url = `${configuration.insightsDevBaseUrl}lifecycle/insights/update-recommendation?tenant_id=${tenantId}&role=${role}`;
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

//Depricated
export const updateOutcome = createAsyncThunk('Update Outcome', async (payload, thunkAPI) => {
  try {
    const response = await axios.put(`${configuration.apiBaseUrl}decision-tracker/add-outcome`, payload, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('error : ', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Update Outcome' });
  }
});

export const addOutcomeToTracker = async ({ tenantId, payload, role }) => {
  try {
    const url = `${configuration.insightsDevBaseUrl}decision/add-outcome?tenant_id=${tenantId}&role=${role}`;
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};

export const fetchAssetLinkStatus = createAsyncThunk('Fetch Asset Link Status', async (payload, thunkAPI) => {
  const { customerId, fromDate, toDate } = payload;
  try {
    const response = await axios.get(
      `${configuration.apiBaseUrl}onboarding/linkage?customerId=${customerId}&fromDate=${fromDate}&toDate=${toDate}`,
      createHeadersWithToken()
    );
    const data = response.data;
    // console.log('Received Data:', data?.data?.assetLinkStatus);
    return data?.data?.assetLinkStatus || [];
  } catch (error) {
    console.error('Error fetching data:', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Fetch Asset Link Status' });
  }
});

//ask Asato Post
export const AskAsatoPost = createAsyncThunk('Post Asato', async (query, thunkAPI) => {
  //  const url = `https://ask-asato.azurewebsites.net/api/v1/get-table`
  // console.log('query', query);
  const url = `${configuration.askAsato}get-table?page=${query.page}`;
  const body = {
    query: query?.text
  };

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: url,
    ...askAsatoToken(),
    data: JSON.stringify(body)
  };

  try {
    let res = await axios.request(config);

    if (res) {
      // console.log('res', res.data);
      return res.data.dataframe;
    }
  } catch (error) {
    if (error) {
      const Err = error.response.data.error;
      // console.log('error.payload', thunkAPI.rejectWithValue(error.payload.response.data.error));
      return thunkAPI.rejectWithValue(Err);
    }
  }
});

export const AskAsatoPost1 = async (query) => {
  //  const url = `https://ask-asato.azurewebsites.net/api/v1/get-table`
  // console.log('query', query);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  await delay(3000); // Wait for 3 seconds

  const allQuestions = Object.keys(ASK_ASATO_DEMO_STUB);

  const key = allQuestions.find((question) => question.toLowerCase().includes(query?.text.toLowerCase()));

  return ASK_ASATO_DEMO_STUB[key] || 'Oops';
};

export const AskAsatoPostDownload = async (query) => {
  const url = `${configuration.askAsato}get-table`;
  const body = {
    query: query?.text
  };

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: url,
    ...askAsatoToken(),
    data: JSON.stringify(body)
  };

  try {
    let res = await axios.request(config);

    if (res) {
      return res.data.dataframe;
    }
  } catch (error) {
    if (error) {
      const Err = error.response.data.error;
      return Err;
    }
  }
};

//Ask Asato Get
export const AskAsatoGet = createAsyncThunk('GetAsato', async (thunkAPI) => {
  const Url = `${configuration.askAsato}view-logs`;
  try {
    const response = await axios.get(Url, askAsatoToken());
    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return thunkAPI.rejectWithValue({ ...error, name: 'Get Ask Asato' });
  }
});

//Hightlights -> insights
export const fetchInsightHighlights = createAsyncThunk('insightHighlights', async (payload, thunkAPI) => {
  const { customerId, fromDate, toDate, screen, tableName = 'insight highlights' } = payload;
  try {
    const response = await axios.get(
      `${configuration.apiBaseUrl}insights/list?fromDate=${fromDate}&toDate=${toDate}&screen=${screen}&tableNames=${tableName}&customerId=${customerId}`,
      createHeadersWithToken()
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue({ error, name: 'Insight Highlights' });
  }
});

// selected charts
export const updateSelectedCharts = createAsyncThunk('selected charts', async (payload, thunkAPI) => {
  let Url = `${configuration.apiBaseUrl}chart/preference?fromDate=${payload.startDate}&toDate=${payload.endDate}&screen=${payload.screen}&section=graphicalinsights`;

  try {
    const res = await axios.put(Url, payload.selected, createHeadersWithToken());
    return res.data;
  } catch (error) {
    // console.log('error', error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

// User List for Access mgmt
export const getUsersList = createAsyncThunk('All Users', async (useDetail, thunkAPI) => {
  let Url = `${configuration.adminURL}data-wrapper/user-details?tenant_id=${userCustomerId}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    // console.log('res sss', res);
    if (res) return res.data.data;
  } catch (error) {
    if (error) {
      // console.log('error ddd', error);
      return thunkAPI.rejectWithValue({ ...error, name: 'Report Users' });
    }
  }
});

export const getAskAsatoSuggestions = (query) => {
  const url = `${configuration.askAsatoAutoComplete}search?q=${query}`;
  return axios.get(url, askAsatoToken());
};

export const getMainServices = createAsyncThunk('MainServices', async (thunkAPI) => {
  const Url = `${configuration.adminURL}data-wrapper/admin/list-providers?tenant_id=${userCustomerId}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCategories = createAsyncThunk('categories-list', async (thunkAPI) => {
  const Url = `${configuration.adminURL}data-wrapper/v2/admin/list-categories`;

  // https://application-dev.asato.ai/data-wrapper/v2/admin/list-categories
  try {
    let res = await axios.get(Url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCategoryDetails = createAsyncThunk('category_details', async (details, thunkAPI) => {
  const param = 'list-connectors';
  const Url = `${configuration.adminURL}data-wrapper/v2/admin/${param}?category=${details?.category}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getConnecterDetails = createAsyncThunk('connector_details', async ({ id, isEdit }, thunkAPI) => {
  const param = isEdit ? 'existing-connector-input-params' : 'get-connector-input-params';
  const Url = `${configuration.adminURL}data-wrapper/v2/admin/${param}?connector_instance_id=${id}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

//stub
export const getServices = createAsyncThunk('Services', async ({ id, provider }, thunkAPI) => {
  const url = `${configuration.adminURL}data-wrapper/admin/list-ingestion-sources?tenant_id=${userCustomerId}&connection_type=${id}&provider=${provider}`;
  try {
    let res = await axios.get(url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
//stub
export const getUniqueServices = createAsyncThunk('uniqueService', async (cards, thunkAPI) => {
  let url = `${
    configuration.adminURL
  }data-wrapper/admin/ingestion-source-form?tenant_id=${userCustomerId}&ingestion_source_list=${cards.join(',')}`;
  try {
    let res = await axios.get(url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

// Manual Upload Table

export const getManualUploadTable = createAsyncThunk('upload table', async (options) => {
  // The API below fetch the table for the given section
  // supported sec types - 'charts', 'tiles'
  const { tenant_id = userCustomerId, blob_url, page_number, page_size, has_page_count } = options;

  // https://application-dev.asato.ai/data-wrapper/get-blob-data?tenant_id=wellstreet&blob_url=https%3A%2F%2Fstbackendservicedev.blob.core.windows.net%2Fwellstreet%2Fsource-data%2Flicense-manual-upload%2Flicense_data_template_v1.csv&page_number=0&page_size=10&has_page_count=true
  // console.log('options', options);
  const params = new URLSearchParams({
    tenant_id,
    blob_url,
    page_number,
    ...(page_size && { page_size }),
    ...(has_page_count && { has_page_count })
  });

  const Url = `${configuration.adminURL}data-wrapper/get-blob-data?${params.toString()}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    // console.log('res', res);
    if (res) {
      return res?.data || [];
    }
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
});

//stub
export const getInputs = createAsyncThunk('inputs', async (id, thunkAPI) => {
  try {
    let res = await axios.get(`http://localhost:4100/inputs/${id}`);

    if (res) {
      return res?.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

//idp
export const fetchIdpList = async (email) => {
  try {
    const response = await axios.get(`${configuration.newApiBaseUrl}idp/all?email=${email}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch IDP list:', error);
    return [];
  }
};

//Keycloak logout
export const keycloakLogout = async () => {
  const url = `${configuration.newApiBaseUrl}token/logout`;

  let config = {
    method: 'post',
    url: url,
    ...createHeadersWithToken()
  };

  try {
    let res = await axios.request(config);

    if (res) {
      return res.data;
    }
  } catch (error) {
    if (error) {
      const Err = error.response;
      return Err;
    }
  }
};

//stub
export const PostLink = createAsyncThunk('linkPost', async (data, thunkAPI) => {
  console.log('data', data);
  try {
    const response = await axios.post(`http://localhost:4100/links`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

// ************ Hardware screen APIs - Start

// MetaData (Sections)

export const getSections = async (options) => {
  try {
    let res = await axios.get(
      `${configuration.insightsDevBaseUrl}screens/sections?tenant_id=${options.tenant_id}&screen_name=${options.screen_name}&role=${options.role}`,
      createHeadersWithToken()
    );

    if (res) return res?.data;
  } catch (error) {
    if (error) {
      throw error;
    }
  }
};

// Cards (Tiles)

export const getTiles = async (options) => {
  const { from_date, to_date, tenant_id, screen_name, role, asset_id, asset_type } = options;

  const params = new URLSearchParams({
    tenant_id,
    screen_name,
    role,
    from_date,
    to_date,
    ...(asset_id && { asset_id }),
    ...(asset_type && { asset_type })
  });

  const Url = `${configuration.insightsDevBaseUrl}screens/tiles?${params.toString()}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) return res;
  } catch (error) {
    console.log(error);
  }
};

// Charts

export const getChartsV2 = async (options) => {
  if (!options?.is_allowed) delete options.is_allowed;
  if (!options?.asset_id) delete options.asset_id;
  if (!options?.asset_type) delete options.asset_type;
  if (!options?.data_product_id) delete options.data_product_id;

  const params = new URLSearchParams(options);

  let url = `${configuration.insightsDevBaseUrl}screens/charts?${params.toString()}`;

  try {
    let res = await axios.get(url, createHeadersWithToken());
    if (res) return res.data;
  } catch (error) {
    console.error('Error fetching charts:', error);
  }
};

// !hard coded insight fetch - will be removed once 'screen/insights' api is ready

export const getInsights = async (options) => {
  const { tenant_id, screen_name, from_date, to_date, role, status } = options;
  let url = `${configuration.insightsDevBaseUrl}screens/insights?tenant_id=${tenant_id}&screen_name=${screen_name}&from_date=${from_date}&to_date=${to_date}&role=${role}`;

  if (status) {
    url = `${configuration.insightsDevBaseUrl}lifecycle/insights/status?tenant_id=${tenant_id}&from_date=${from_date}&to_date=${to_date}&role=${role}&status=${status}`;
  }
  try {
    let res = await axios.get(url, createHeadersWithToken());
    if (res?.data) return res.data;
  } catch (error) {
    if (error) console.log(error);
  }
};

export const getInsightInfo = async (options) => {
  const { tenant_id, screen_name, insight_id } = options;
  let Url = `${configuration.insightsDevBaseUrl}screens/insights/info?tenant_id=${tenant_id}&screen_name=${screen_name}&insight_id=${insight_id}`;
  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) return res.data;
  } catch (error) {
    if (error) console.log(error);
  }
};

// Tables

export const getDetailedTable = createAsyncThunk('detailed table', async (options, thunkAPI) => {
  // The API below fetch the table for the given section
  // supported sec types - 'charts', 'tiles'
  const {
    api,
    tenant_id,
    from_date,
    to_date,
    screen_name,
    role,
    asset_id,
    asset_type,
    data_product_id,
    footer_id,
    page_number,
    page_size,
    has_page_count,
    sort_by,
    sort_order,
    download,
    search_query,
    search_column,
    is_allowed = false
  } = options;

  // console.log('options', options);
  const params = new URLSearchParams({
    tenant_id,
    from_date,
    to_date,
    data_product_id,
    screen_name,
    role,
    page_number,
    is_allowed,
    ...(page_size && { page_size }),
    ...(has_page_count && { has_page_count }),
    ...(asset_id && { asset_id }),
    ...(asset_type && { asset_type }),
    ...(footer_id && { footer_id }),
    ...(sort_by && { sort_by }),
    ...(sort_order && { sort_order }),
    ...(download && { download }),
    ...(search_query && { search_query }),
    ...(search_column && { search_column })
  });

  const Url = `${configuration.insightsDevBaseUrl}screens/${api}/table?${params.toString()}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    // console.log('res', res);
    if (res) {
      return { data: res } || [];
    }
  } catch (error) {
    if (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ ...error, name: 'Card Table Data' });
    }
  }
});

export const getDetailedTableVersionSecond = createAsyncThunk('insights table', async (options) => {
  if (!options?.search_query) delete options.search_query;
  if (!options?.search_column) delete options.search_column;
  if (!options?.sort_by) delete options.sort_by;
  if (!options?.sort_order) delete options.sort_order;

  const params = new URLSearchParams(options);
  // const url = `http://localhost:5001/0`;
  const url = `${configuration.insightsDevBaseUrl}screens/table?${params.toString()}`;

  try {
    let res = await axios.get(url, createHeadersWithToken());
    // console.log('res', res);
    if (res) {
      return res?.data || [];
    }
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
});

export const getDetailedTableV2 = createAsyncThunk('insights table', async (options) => {
  // The API below fetch the table for the given section
  // supported sec types - 'charts', 'tiles'
  const {
    api,
    tenant_id,
    from_date,
    to_date,
    screen_name,
    role,
    asset_id,
    asset_type,
    data_product_id,
    footer_id,
    page_number,
    page_size,
    has_page_count,
    sort_by,
    sort_order,
    download,
    search_query,
    search_column,
    source_type
  } = options;

  // console.log('options', options);
  const params = new URLSearchParams({
    tenant_id,
    from_date,
    to_date,
    data_product_id,
    screen_name,
    role,
    page_number,
    ...(page_size && { page_size }),
    ...(has_page_count && { has_page_count }),
    ...(asset_id && { asset_id }),
    ...(asset_type && { asset_type }),
    ...(footer_id && { footer_id }),
    ...(sort_by && { sort_by }),
    ...(sort_order && { sort_order }),
    ...(download && { download }),
    ...(search_query && { search_query }),
    ...(search_column && { search_column }),
    ...(source_type && { source_type })
  });

  const url = `${configuration.insightsDevBaseUrl}screens/${api}/table?${params.toString()}`;

  try {
    let res = await axios.get(url, createHeadersWithToken());
    // console.log('res', res);
    if (res) {
      return res?.data || [];
    }
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
});

// ************ Hardware screen APIs - End

//Admin - Source

export const getSources = createAsyncThunk('Sources', async (details, thunkAPI) => {
  const { page_number, source_type, page_size, has_page_count, sort_by, sort_order, search_query, search_column } = details;
  const params = new URLSearchParams({
    page_number,
    ...(source_type && { source_type }),
    ...(page_size && { page_size }),
    ...(has_page_count && { has_page_count }),
    ...(sort_by && { sort_by }),
    ...(sort_order && { sort_order }),
    ...(search_query && { search_query }),
    ...(search_column && { search_column })
  });

  const Url = `${configuration.adminURL}data-wrapper/sources?tenant_id=${userCustomerId}&${params.toString()}`;

  try {
    let res = await axios.get(Url, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    if (res) return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'sources' });
  }
});

//Admin - Source Details
export const getJobDetails = createAsyncThunk('job-details', async (details, thunkAPI) => {
  // let Url = `${configuration.insightsDevBaseUrl}screens/charts`;

  const { source_id } = details;
  const params = new URLSearchParams({
    ...(source_id && { source_id })
  });
  let Url = `${configuration.insightsDevBaseUrl}admin/source-info?tenant_id=${userCustomerId}&${params.toString()}`;

  try {
    let res = await axios.get(Url, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });

    if (res) return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'job details' });
  }
});

// get Invoice Details
export const getInvoiceDetails = createAsyncThunk('invoice-details', async (details, thunkAPI) => {
  const { invoiceId, is_pdf } = details;

  let Url = `${configuration.adminURL}invoice/extract/${invoiceId}?is_pdf=${is_pdf}`;

  try {
    let res = await axios.get(Url, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });

    if (res) return res.data;
  } catch (error) {
    // throw new error();
    return thunkAPI.rejectWithValue({ ...error, name: 'invoice details' });
  }
});

//update invoice
export const UpdateInvoiceDetails = async (Details) => {
  const { invoiceId, data } = Details;
  let Url = `${configuration.adminURL}invoice/extract/${invoiceId}`;

  try {
    let res = await axios.put(Url, data, createHeadersWithToken());

    if (res) {
      return res;
    }
  } catch (error) {
    console.log('error', error);
    if (error) return error?.response?.data?.error;
  }
};

//invoice status in table
export const updateAssignmentStatus = async (invoiceId, action) => {
  const url = `${configuration.adminURL}invoice/${invoiceId}/assignments/status`;

  const data = {
    action: action
  };

  try {
    const response = await axios.put(url, data, createHeadersWithToken());

    return response;
  } catch (error) {
    console.error('Error updating assignment status:', error);

    if (error.response) {
      return error.response.data.error;
    } else {
      return 'An unknown error occurred';
    }
  }
};

//update notes to invoice
export const updateInvoiceNotes = async (invoiceId, notes) => {
  try {
    const url = `${configuration.adminURL}invoice/${invoiceId}/notes`;

    const data = { note: notes };

    const response = await axios.put(url, data, createHeadersWithToken());

    return response;
  } catch (error) {
    console.error('Error updating notes:', error);
    throw error;
  }
};

// Get existing notes for an invoice
export const getInvoiceNotes = async (invoiceId) => {
  try {
    const url = `${configuration.adminURL}invoice/${invoiceId}/notes`;

    const response = await axios.get(url, createHeadersWithToken());

    return response;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

// Invoice categories
export const getAllocationOptions = async () => {
  try {
    const url = `${configuration.adminURL}invoice/categories`;
    const response = await axios.get(url, createHeadersWithToken());
    return response;
  } catch (error) {
    console.error('Error fetching allocation options:', error);
    throw error;
  }
};

//cost centre
export const getCostCenters = createAsyncThunk('cost_centers', async (details, thunkAPI) => {
  const { invoiceId, allocationType } = details;

  let Url = `${configuration.adminURL}invoice/allocation/${invoiceId}/cost-centers${allocationType ? `?category=${allocationType}` : ''}`;

  try {
    let res = await axios.get(Url, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });

    if (res) return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'CostCenters' });
  }
});

//update cost centre
export const updateCostCenters = async (invoiceId, details, allocationType) => {
  try {
    const url = `${configuration.adminURL}invoice/allocation/${invoiceId}/cost-centers${
      allocationType ? `?category=${allocationType}` : ''
    }`;

    const response = await axios.put(url, details, createHeadersWithToken());

    return response;
  } catch (error) {
    console.error('Error updating CostCenters:', error);
    throw error;
  }
};

//license upload type
export const getAllowedFileTypes = async (type) => {
  try {
    const response = await axios.get(`${configuration.adminURL}data-wrapper/get-allowed-file-types/${type}`, createHeadersWithToken());
    return response;
  } catch (error) {
    console.error('Error fetching allowed file types:', error);
    throw error;
  }
};

//invoice get list form fields
export const getLicenseFormFields = async () => {
  try {
    const response = await axios.get(`${configuration.adminURL}data-wrapper/get-license-form?upload_type=License_FORM`, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error fetching license form fields:', error);
    throw error;
  }
};

//update users assignee
export const assigneeInvoiceUsers = async (invoiceId, details) => {
  try {
    const url = `${configuration.adminURL}invoice/allocation/${invoiceId}/assigned-to-users`;

    const response = await axios.put(url, details, createHeadersWithToken());

    return response;
  } catch (error) {
    console.error('Error updating CostCenters:', error);
    throw error;
  }
};

export const getTenantUsers = async () => {
  try {
    const response = await axios.get(`${configuration.newApiBaseUrl}user/`, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error getting users:', error);
  }
};
export const getBannerDetails = createAsyncThunk('banner-details', async (details, thunkAPI) => {
  console.log('details', details);
  // let Url = `${configuration.insightsDevBaseUrl}screens/charts`;
  let Url = `${configuration.insightsDevBaseUrl}screens/asset/list?tenant_id=${userCustomerId}&type=${details?.type}`;

  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res) return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ ...error, name: 'job details' });
  }
});

// Source detail download URL
export const getDownloadUrl = async (fileUrl) => {
  try {
    const response = await axios.get(`${configuration.insightsDevBaseUrl}admin/download-url?resource_url=${encodeURIComponent(fileUrl)}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching download URL:', error);
    throw error;
  }
};

export const downloadAPI = async (req_Params) => {
  if (!req_Params?.search_query) delete req_Params.search_query;
  if (!req_Params?.search_column) delete req_Params.search_column;
  if (!req_Params?.sort_by) delete req_Params.sort_by;
  if (!req_Params?.sort_order) delete req_Params.sort_order;

  req_Params.download = true;

  const params = new URLSearchParams(req_Params);

  const downloadUrl = `${configuration.insightsDevBaseUrl}screens/table?${params}`;

  try {
    const { data } = await axios.get(downloadUrl, createHeadersWithToken());
    return data;
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const getServiceEditMenu = createAsyncThunk('edit uniqueService', async (name, thunkAPI) => {
  const url = `${configuration.adminURL}data-wrapper/admin/edit-ingestion-source-form?tenant_id=${userCustomerId}&ingestion_source=${name}`;

  try {
    let res = await axios.get(url, createHeadersWithToken());

    if (res) {
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const verifySource = async (source, details) => {
  const url = `${configuration?.adminURL}data-wrapper/admin/validate-ingestion-source-form?tenant_id=${userCustomerId}&ingestion_source_list=${source}`;
  console.log('url', url);

  const formData = new FormData();

  Object.keys(details).forEach((key) => {
    formData.append(key, details[key]);
  });

  const axiosConfig = {
    method: 'post',
    url: url,
    data: formData,
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`
    }
  };

  try {
    let res = await axios(axiosConfig);

    return res;
  } catch (error) {
    console.error('Error during file upload:', error);
    return error;
  }
};

export const saveSource = async (source, details) => {
  const param = 'save-connector-input-params';
  const url = `${configuration?.adminURL}data-wrapper/v2/admin/${param}?connector_instance_id=${source}`;
  const formData = new FormData();

  Object.keys(details).forEach((key) => {
    formData.append(key, details[key]);
  });

  const axiosConfig = {
    method: 'post',
    url: url,
    data: formData,
    headers: {
      Authorization: `Bearer ${getTokenFromSessionStorage()}`
    }
  };

  try {
    let res = await axios(axiosConfig);

    return res;
  } catch (error) {
    console.error('Error during file upload:', error);
    return error;
  }
};

export const SourceDropdown = async (screen_name) => {
  const params = new URLSearchParams({
    tenant_id: userCustomerId,
    options_type: 'UploadType',
    ...(screen_name && { screen_name })
  });

  let Url = `${configuration.adminURL}data-wrapper/get-dropdown-options?${params.toString()}`;
  try {
    let res = await axios.get(Url, createHeadersWithToken());
    if (res?.data) return res.data;
  } catch (error) {
    if (error) console.log(error);
  }
};

export const CompleteSource = createAsyncThunk('complete_source', async (pid, thunkAPI) => {
  let Url = `${configuration.adminURL}internal/process-license-stage-data?tenant_id=${userCustomerId}&pid=${pid}`;

  try {
    let res = await axios.post(Url, createHeadersWithToken());

    if (res) return res.data;
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'complete_source' });
  }
});

// * Dataset API
export const getDataSetList = async (queryParamObject) => {
  const queryParams = toUrlParams(queryParamObject);
  try {
    const response = await axios.get(`${configuration.insightsDevBaseUrl}datasets/list?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromSessionStorage()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching download URL:', error);
    return error;
  }
};

//add new Task
export const AddTaskData = createAsyncThunk('Add Task', async (newTask, thunkAPI) => {
  const options = {
    method: 'POST',
    ...createHeadersWithToken(),
    body: JSON.stringify(newTask)
  };
  try {
    // https://tms-dev.asato.ai/api/v1/tasks/create

    let res = await fetch(`${configuration.admintmsURL}tasks/create`, options);
    console.log('res', res);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error) return thunkAPI.rejectWithValue({ ...error, name: 'Add Access' });
  }
});

//update task
export const UpdateTask = createAsyncThunk('Update task', async (Details, thunkAPI) => {
  let Url = `${configuration.admintmsURL}insights/update`;
  try {
    let res = await axios.put(Url, Details, createHeadersWithToken());

    if (res) {
      return res;
    }
  } catch (error) {
    console.log('error', error);
    if (error) return thunkAPI.rejectWithValue(error?.response?.data?.error);
  }
});

//Get the Asset List on basis of Asset Type (i.e. Hardware, Software, Location)
export const getAssetListFromTypeURL = async ({ assetType, searchKey, searchValue }) => {
  let url = `${configuration.insightsDevBaseUrl}api/v1/list/assets?type=${assetType}`;
  if (searchKey && searchValue) {
    url = `${url}&search_key=${searchKey}&search_value=${searchValue}`;
  }

  try {
    const response = await axios.get(url, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('Error fetching asset list:', error);
    return error;
  }
};

export const getAssetOverview = async ({ assetId, assetType }) => {
  const url = `${configuration.insightsDevBaseUrl}api/v1/asset/${assetId}/overview?asset_type=${assetType}`;

  try {
    const response = await axios.get(url, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('Error fetching asset overview:', error);
    return error;
  }
};

export const getNodeById = async (asset_id, node_name, assetType) => {
  const url = `${configuration.insightsDevBaseUrl}graph/get_node_by_id?tenant_id=${userCustomerId}&node_id=${asset_id}&node_name=${node_name}&assetType=${assetType}`;
  try {
    let res = await axios.get(url, createHeadersWithToken());
    if (res) return res.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const getAsset360Properties = async ({ fieldTableId, assetId, search_column, search_query }) => {
  let url = `${configuration.insightsDevBaseUrl}screens/table?id=${fieldTableId}&asset_id=${assetId}`;
  if (search_column) {
    url = `${url}&search_column=${search_column}&search_query=${search_query}`;
  }
  const data = await axios.get(url, createHeadersWithToken());
  return data;
};

export const getTableDataApi = async ({ id, ...rest }) => {
  const url = `${configuration.insightsDevBaseUrl}screens/table?id=${id}&${toUrlParams(rest)}`;
  const response = await axios.get(url, createHeadersWithToken());
  return response.data;
};
//License VIA Form
export const createLicenseUsingForm = async (formData) => {
  try {
    const response = await axios.post(`${configuration.adminURL}data-wrapper/create-license-using-form`, formData, {
      ...createHeadersWithToken()
    });
    return response;
  } catch (error) {
    console.error('Error creating license:', error);
    throw error.response || error;
  }
};

export const getChartById = async ({ id, payload = {} }) => {
  const url = `${configuration.insightsDevBaseUrl}api/v1/dataproducts/chart`;
  try {
    const response = await axios.post(url, { id, ...payload }, createHeadersWithToken());
    return response.data;
  } catch (error) {
    console.error('Error fetching chart:', error);
    return error;
  }
};

//todo: need remove this api after state is removed
export const getJobDetailsTable = createAsyncThunk('job_details_table', async (options) => {
  if (!options?.search_query) delete options.search_query;
  if (!options?.search_column) delete options.search_column;
  if (!options?.sort_by) delete options.sort_by;
  if (!options?.sort_order) delete options.sort_order;

  const params = new URLSearchParams(options);
  const url = `${configuration.insightsDevBaseUrl}screens/table?${params.toString()}`;

  try {
    let res = await axios.get(url, createHeadersWithToken());
    if (res) {
      return res?.data || [];
    }
  } catch (error) {
    console.log(error);
  }
});
