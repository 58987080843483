import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Delete } from '@mui/icons-material';
import { Box, Chip, FormControl, Link as MuiLink, MenuItem, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import { DocumentText1, Edit, Edit2, ExportSquare, Eye, TickCircle } from 'iconsax-react';

import { getDownloadUrl, ToggleUserEnable, updateAssignmentStatus } from 'api/api';
import { STATUS_COLOR } from 'constants/colors';
import { TASK_STATUS } from 'constants/constants';
import useToast from 'hooks/useToast';
import { setSelectedId } from 'store/slices/CategoryDetailsSlice';
import { changeBannerId } from 'store/slices/PageSlice';
import { changeStep, SetIsEditTrue } from 'store/slices/StepperSlice';
import { StyledChip, StyledIconWrapper, StyledSelect } from 'styled-components/global';
import GlobalButton from 'ui-component/buttons';
import CustomizedSwitches from 'ui-component/buttons/CustomSwitch';
import ErrorMessageWithIcon from 'ui-component/Modal/ErrorMessageWithIcon';
import PopOverTab from 'ui-component/Modal/PopOverTab';
import UpdateUserModal from 'ui-component/Modal/UpdateModal';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';
import { formatNumber } from 'utils';
import { useFunctionContext } from 'views/common/FunctionContext';

import MyResponsiveDataGrid from '../MobileScreenTable';

import DataGridTable from './DataGridTable';
import styles from './Styles/DataSourceTableStyles';

const useStyles = makeStyles(() => ({
  True: {
    ...styles.chip,
    ...styles.activeChip
  },
  False: {
    ...styles.chip,
    ...styles.inactiveChip
  },
  navlink: {
    ...styles.navlink
  },
  urlButton: {
    ...styles.urlButton
  }
}));

// Temporary since we have only view and to manage state
export const ActionIcon = ({ onClick }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box sx={styles.actionIcon} title="View" onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Eye size="20" color={hover ? '#2A76F4' : '#818690'} variant={hover ? 'Bulk' : 'Outline'} style={{ marginRight: '8px' }} />
      View
    </Box>
  );
};

function DataSourceTable({
  columnGroupingModel,
  tableData = [],
  tableHeader = [],
  selectedChip,
  loading,
  handlePageChange,
  paginationModel,
  hideFooter,
  handleSmRowsPerPage,
  handleSmPage,
  sortingMode,
  handleSorting,
  disableColumnSorting,
  disableColumnFilter,
  onFilterChange,
  tableTotalCount,
  requestParams,
  apiCallOnPage,
  handleTaskOpen,
  handleStatusChange,
  onRowDelete,
  toolbarConfig,
  onEditClick,
  onDeleteClick
}) {
  const classes = useStyles();
  const [updateModal, setUpdateModal] = useState(false);
  const [selected, setSelected] = useState({});
  const role = localStorage.getItem('persona');
  const [downloadUrl, setDownloadUrl] = useState('');
  const downloadLinkRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 750px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const formControlValues = {};
  const pageType = useSelector((state) => state?.page?.page);

  const handleOpenBar = () => {
    showToast('User Details Updated Successfully', {
      severity: 'success',
      duration: 2000
    });
  };

  // ! hard coding alert
  // todo @mohit
  const { sharedFunction } = useFunctionContext() || {};
  const { taskStatus } = sharedFunction || '';

  useEffect(() => {
    if (downloadUrl && downloadLinkRef.current) {
      downloadLinkRef.current.click();
      setDownloadUrl(null);
    }
  }, [downloadUrl]);

  const handleFileDownload = async (fileUrl) => {
    try {
      const downloadUrl = await getDownloadUrl(fileUrl);
      setDownloadUrl(downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleRow = (row) => {
    const link = row?.redirectUrl ? row?.redirectUrl : `/link?type=${selectedChip}&source_id=${row.name}`;
    navigate(link, { replace: false });
  };

  const handleNavigate = (row, item) => {
    // Handle the navigation completely from backend
    // or add a case for task screen
    // ! Hard coding alert!
    if (pageType === 'tasks' || window?.location?.pathname?.includes('/tasks/overview')) {
      navigate(`/tasks/${row?.id}`);
    } else if (pageType === 'invoice-list') {
      navigate(`/invoice-list/${row?.invoiceId}?view=extracted-data`);
    } else {
      navigate(`/asset?id=${row[item['assetIdColumn']]}&type=${item.assetType}`);
      dispatch(changeBannerId(row[item['assetIdColumn']]));
    }
  };

  const handleMenu = (item) => {
    sessionStorage.setItem('selectedId', item?.name);
    dispatch(setSelectedId(item?.name));
    navigate(`/link/API/connector-input-params`);
    dispatch(changeStep(1));
    dispatch(SetIsEditTrue(true));
  };

  const MenuOptionIcon = ({ IconComponent, label }) => {
    const [hover, setHover] = useState(false);

    return (
      <Box gap={1} sx={styles.actionIcon} title={label} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <IconComponent size="24" color={hover ? '#2A76F4' : '#818690'} variant={hover ? 'Bulk' : 'Outline'} />
        {label}
      </Box>
    );
  };

  const menuOptions = [
    {
      label: 'View',
      icon: <MenuOptionIcon IconComponent={Eye} label="View" />,
      onClick: handleRow
    },
    {
      label: 'Edit',
      icon: <MenuOptionIcon IconComponent={Edit} label="Edit" />,
      onClick: handleMenu
    }
    // {
    //   label: 'Delete',
    //   icon: <Trash size="24" color="#818690" />,
    //   onClick: (item) => handleDeleteOpen(item)
    // }
  ];

  const handleSwitchChange = (item, index) => {
    const { id } = item;
    const req = {
      isEnabled: !item['accountEnabled'],
      customerId: id,
      currentPersona: role,
      index
    };

    dispatch(ToggleUserEnable(req));
  };

  const handleEdit = (item) => {
    setUpdateModal(true);
    setSelected(item);
  };

  const closeModal = () => {
    setUpdateModal(false);
    setSelected({});
  };

  // Customize the data rendering
  const renderConnectionStatus = (data, params, i) => {
    const value = params?.value?.toString();
    const isLongValue = value && value.length > 18;
    const isEmpty = params?.value === '' || params?.value === null || params?.value === undefined;

    if (data?.dataType === 'nonEmpty' && isEmpty) {
      return (
        <Box sx={styles.emptyRequiredCell}>
          <Typography color="error">{'-'}</Typography>
        </Box>
      );
    }

    const color = data?.columnColor || 'Default';

    const status_color = STATUS_COLOR[value] || 'Default';

    if (isEmpty) return '-';

    if (data.isClickable) {
      return (
        <MuiLink
          sx={{
            cursor: 'pointer',
            color: status_color
          }}
          onClick={(e) => {
            e.preventDefault();
            handleNavigate(params.row, data);
          }}
        >
          {value}
        </MuiLink>
      );
    }

    switch (data.key) {
      case 'personas': {
        const label = params?.value?.map((i) => i?.name).join(', ');
        return (
          <Tooltip title={label}>
            <Typography variant="bodySm" sx={styles.personasTypography}>
              {label}
            </Typography>
          </Tooltip>
        );
      }
      default:
        break;
    }

    switch (data?.dataType) {
      case 'status': {
        return (
          <Box className={params.value ? classes?.True : classes?.False} mt={1} sx={{ textTransform: 'capitalize' }}>
            {params.value ? 'Active' : 'Inactive'}
          </Box>
        );
      }
      case 'status_info': {
        return params?.value ? (
          <Box display="flex" alignItems="center" gap={3} my={1.5} sx={{ textTransform: 'capitalize' }}>
            <Chip variant="outlined" label={value} color={params.value === 'success' ? 'success' : 'error'} />
          </Box>
        ) : (
          '-'
        );
      }
      case 'color_chip': {
        /* eslint-disable-next-line */
        // const sla = SLA_FACTOR.find((sla) => sla.value === value) || 'default';
        // console.log('sla', sla);
        return (
          <StyledChip
            variant="outlined"
            label={value}
            color={STATUS_COLOR[value] || 'warning'}
            // sx={{ color: `${sla?.fill} !important`, border: 'none', borderRadius: 1.5, backgroundColor: sla?.bgColor }}
          />
        );
      }
      // TODO : change the name of the key to button_group
      // * Reason : A button group will not always be for status.
      case 'status_button': {
        /* eslint-disable-next-line */
        const type = params?.value?.type;
        // console.log('type', type);
        /* eslint-disable-next-line */
        const currentValue = formControlValues[params?.row?.name] || params?.value?.currentStatus;
        /* eslint-disable-next-line */
        const factor = TASK_STATUS?.find((sla) => sla.value === currentValue) || 'default';
        /* eslint-disable-next-line */
        const options = params?.value?.options || [];
        switch (type) {
          case 'button':
            return (
              <Box display="flex" gap={1} mt={1}>
                {options.map((btn) => (
                  <GlobalButton
                    key={btn?.apiKey}
                    variant="outlined"
                    onClick={() => handleTaskOpen(params?.row, btn?.apiKey == 'Accepted' ? 'accept' : 'reject')}
                  >
                    {btn?.label}
                  </GlobalButton>
                ))}
              </Box>
            );
          case 'select':
            return (
              <FormControl size="small">
                {/* IDK what MUI devs were thinking? */}
                {/* TODO : Find a way to put this in styled smh */}
                <StyledSelect
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}
                  stylevariant={'no-border'}
                  color={STATUS_COLOR[currentValue] || 'warning'}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentValue}
                  onChange={(e) => handleStatusChange(e, params.row.id, currentValue, e.target.value)}
                  disabled={
                    options.length === 1 ||
                    (pageType === 'task-detail' &&
                      (taskStatus === 'Completed' || taskStatus === 'Rejected' || taskStatus === 'Closed by User'))
                  }
                >
                  {options?.map((task) => (
                    <MenuItem key={task.apiKey} value={task.label}>
                      {task.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            );
          default:
            break;
        }
        // console.log('params', params);
        // console.log('data', data);

        break;
      }
      case 'tag': {
        return params?.value ? (
          <Box display="flex" alignItems="center" gap={1} my={1.5}>
            {(Array.isArray(value) ? value : value.split(/[ ,]+/)).map((val, index) => (
              <Chip
                key={index}
                label={val.trim()}
                color={
                  val.trim() === 'Asset' ? 'primary' : val.trim() === 'Cost' ? 'secondary' : val.trim() === 'Usage' ? 'default' : 'error'
                }
              />
            ))}
          </Box>
        ) : (
          '-'
        );
      }
      case 'superTags': {
        return params?.value && (Array.isArray(params.value) ? params?.value?.length > 0 : params.value.trim()) ? (
          <Box sx={styles.superTagsContainer}>
            {(Array.isArray(params.value) ? params.value : params.value.split(',')).map((val, index) => (
              <Typography key={index} variant="body2">
                {val?.trim()}
              </Typography>
            ))}
          </Box>
        ) : (
          '-'
        );
      }
      case 'invoice_status': {
        const { invoiceStatus: sta } = params.row || {};
        const isProcessing = sta === 'Processing';

        const btn = isProcessing
          ? {
              text: 'Processing ↻',
              color: '#434C5B',
              onClick: () => apiCallOnPage(requestParams)
            }
          : null;

        const boxStyle = {
          height: '100%',
          cursor: isProcessing ? 'pointer' : 'default'
        };

        return (
          <MuiLink
            title={params.value || ''}
            className={classes.urlButton}
            onClick={(e) => {
              e.preventDefault();
              if (btn?.onClick) btn.onClick();
            }}
          >
            <Box display="flex-start" sx={boxStyle}>
              {isProcessing && btn?.icon && <>{btn.icon}</>}
              <Typography variant="button" color={btn ? btn.color : status_color} sx={{ textAlign: 'center' }}>
                {isProcessing ? btn.text : params?.value || '-'}
              </Typography>
            </Box>
          </MuiLink>
        );
      }
      case 'link': {
        if (!params?.value) return '-';

        let displayText = 'Link';
        const url = params.value?.toLowerCase();

        if (url?.endsWith('.pdf')) {
          displayText = 'PDF';
        } else if (url?.endsWith('.csv')) {
          displayText = 'CSV';
        }

        return (
          <MuiLink
            title={params.value || ''}
            href="#"
            sx={{ color: color }}
            onClick={(e) => {
              e.preventDefault();
              handleFileDownload(params.value);
            }}
          >
            {displayText}
          </MuiLink>
        );
      }
      case 'mouseEvent': {
        return <ActionIcon onClick={() => handleRow(params.row)} />;
      }
      case 'fileView': {
        const fileUrl = params?.value;
        return fileUrl ? (
          <Box sx={styles.fileViewBox}>
            <DocumentText1 size="18" />
            <Typography sx={styles.fileViewTypography}>Terms.doc</Typography>
            <MuiLink href={fileUrl} target="_blank" sx={styles.fileViewLink}>
              View
            </MuiLink>
          </Box>
        ) : (
          '-'
        );
      }
      case 'profileName': {
        const fullName = params?.value || '';
        const nameParts = fullName.split(' ');
        const initials = nameParts.length > 1 ? nameParts[0][0] + nameParts[nameParts.length - 1][0] : nameParts[0][0];

        return (
          <Box display="flex" alignItems="center" height="100%" gap={2}>
            <Box sx={styles.profileImage}>{initials}</Box>
            <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
              {fullName}
            </Typography>
          </Box>
        );
      }
      case 'redirectUrl': {
        if (pageType === 'dataset-detail') {
          return <ActionIcon onClick={() => navigate(value)} />;
        }
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box mt={0.5}>
              <PopOverTab params={params} divOptions={menuOptions} />
            </Box>
          </Box>
        );
      }
      case 'error': {
        return params.value ? <ErrorMessageWithIcon message={params.value} /> : '';
      }
      case 'verified': {
        return <TickCircle size="24" color="#5DB806" />;
      }
      case 'switch': {
        return (
          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <CustomizedSwitches onChange={() => handleSwitchChange(params.row, i)} check={params.value} />
            <Edit2
              size="32"
              color="#2A76F4"
              style={{
                height: '20px',
                width: '20px',
                cursor: 'pointer',
                textTransform: 'capitalize'
              }}
              onClick={() => handleEdit(params.row)}
            />
          </Box>
        );
      }
      case 'Url': {
        const status = params.row?.status || '';

        let buttonProps = {};

        if (status === 'success') {
          buttonProps = {
            text: 'View',
            color: 'primary',
            onClick: () => navigate(params.row.redirectUrl)
          };
        } else if (status === 'inProgress') {
          buttonProps = {
            text: 'Refresh ↻',
            color: 'default',
            onClick: () => apiCallOnPage(requestParams)
          };
        } else if (status === 'failed') {
          buttonProps = {
            text: 'Failed',
            color: 'error'
          };
        }

        return (
          <MuiLink
            title={params.value || ''}
            className={classes.urlButton}
            onClick={(e) => {
              e.preventDefault();
              if (buttonProps.onClick) buttonProps.onClick();
            }}
          >
            <Typography variant="button" color={buttonProps.color} sx={{ textTransform: 'capitalize' }}>
              {buttonProps.text}
            </Typography>
          </MuiLink>
        );
      }
      case 'href': {
        const hrefKey = data?.tableRedirectKey || 'redirectUrl';
        const href = params?.row?.[hrefKey];
        const invoiceId = params?.row?.invoiceId;

        const handleClick = async (e) => {
          e.preventDefault();

          if (pageType === 'invoice-list') {
            navigate(href);
            try {
              await updateAssignmentStatus(invoiceId, params?.row?.action);
            } catch (error) {
              console.error('Error updating assignment status:', error);
            }
          } else {
            navigate(href);
          }
        };

        return href ? (
          <MuiLink sx={{ cursor: 'pointer', color: status_color }} onClick={handleClick}>
            {value}
          </MuiLink>
        ) : (
          value
        );
      }
      case 'delete': {
        return (
          <Box onClick={() => onRowDelete(params.row)} display="flex" alignItems="center" justifyContent="flex-start" height={'100%'}>
            <StyledIconWrapper round={'circle'}>
              <Delete
                size="24"
                color={params.row.delete === false ? 'grey' : 'error'}
                sx={{ cursor: params.row.delete === false ? 'not-allowed' : 'pointer' }}
              />
            </StyledIconWrapper>
          </Box>
        );
      }
      case 'data':
      case 'number':
      case 'percentage':
      case 'currency':
      case 'date':
        return (
          <span>
            {formatNumber({
              number: value,
              format: data?.dataType,
              formatCurrency: data?.formatCurrency,
              options: {
                currencyType: params.row?.currencyType,
                formatCurrency: data?.formatCurrency
              }
            })}
          </span>
        );

      case 'external_url':
        return (
          <a style={{ textDecoration: 'underline', color: 'hsl(210, 100%, 36%)' }} href={value} target="_blank" rel="noreferrer">
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {value} <ExportSquare size={14} />
            </div>
          </a>
        );

      case 'modify': {
        return (
          <Box display="flex" alignItems="center" gap={1} height="100%">
            <StyledIconWrapper round={'circle'} onClick={() => onEditClick?.(params.row)}>
              <Edit2 size="20" color="#2A76F4" />
            </StyledIconWrapper>
            <StyledIconWrapper round={'circle'} onClick={() => onDeleteClick?.(params.row)}>
              <Delete size="20" color="error" />
            </StyledIconWrapper>
          </Box>
        );
      }

      default:
        return (
          <OnHoverToolTip variant="children" placement="bottom-start" title={isLongValue ? value : ''}>
            <span style={{ textTransform: 'capitalize' }}>{value}</span>
          </OnHoverToolTip>
        );
    }
  };

  const columnsNotSortable = tableHeader?.filter((data) => data?.isSortable === false).map((data) => data.key);

  const updatedColumn = tableHeader
    ?.filter((data) => data?.display)
    ?.map((data, i) => ({
      field: data?.key,
      headerName: data?.title,
      headerClassName: 'header',
      type: data?.type,
      dataType: data?.dataType,
      flex: 1,
      editable: false,
      filterable: data?.filterable ?? true,
      disableColumnMenu: data?.disableColumnMenu ?? false,
      sortable: !columnsNotSortable.includes(data?.key),
      minWidth: data?.width || 160,
      defaultVisible: data?.defaultVisible ?? true,
      hideable: data?.hideable ?? true,
      renderCell: (params) => renderConnectionStatus(data, params, i)
    }));

  const updatedRow = Array.isArray(tableData)
    ? tableData.map((item) => ({
        ...item,
        // Instead of id in res I am getting _id
        id: item?._id || item?.id
        // status: item?.isActive
      }))
    : [];

  return (
    <div>
      <a
        ref={downloadLinkRef}
        aria-label="Download"
        target="_blank"
        style={{ display: 'none' }}
        href={downloadUrl}
        download
        rel="noreferrer"
      />
      {!isMobile ? (
        <DataGridTable
          toolbarConfig={toolbarConfig}
          columnGroupingModel={columnGroupingModel}
          hideFooter={hideFooter}
          dataSource={updatedRow}
          columns={updatedColumn}
          pagination={false}
          selectedChip={selectedChip}
          loading={loading}
          paginationModel={paginationModel}
          handlePageChange={handlePageChange}
          sortingMode={sortingMode}
          handleSorting={handleSorting}
          disableColumnSorting={disableColumnSorting}
          disableColumnFilter={disableColumnFilter}
          onFilterChange={onFilterChange}
          tableTotalCount={tableTotalCount}
        />
      ) : (
        <MyResponsiveDataGrid
          handleSwitchChange={handleSwitchChange}
          handleEdit={handleEdit}
          loading={loading}
          head={updatedColumn}
          rows={updatedRow}
          paginationModel={paginationModel}
          handleSmPage={handleSmPage}
          handleSmRowsPerPage={handleSmRowsPerPage}
          selectedChip={selectedChip}
          tableTotalCount={tableTotalCount}
        />
      )}
      <UpdateUserModal open={updateModal} selected={selected} handleClose={closeModal} handleOpenBar={handleOpenBar} />
    </div>
  );
}

export default DataSourceTable;
